<template>
  <div class="common-dialog">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="clickModal"
      :close-on-press-escape="false"
      :fullscreen="isFullscreen"
      :destroy-on-close="true"
      :before-close="beforeClose"
      :width="width"
      :modal="modal"
      @closed="dialogClosed"
      @close="closeDialog"
    >
      <template v-if="titleSlot">
        <div slot="title">
          <slot name="dialogTitle" />
        </div>
      </template>
      <div style="padding-top:0px">
        <slot />
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CommonDialog',
  props: {
    width: {
      type: String,
      default: '1000px'
    },
    beforeClose: {
      type: Function,
      default: done => {
        done()
      }
    },
    commonDClass: {
      type: String,
      default: ''
    },
    titleSlot: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '标题'
    },
    modal: {
      type: Boolean,
      default: true
    },
    clickModal: {
      type: Boolean,
      default: false
    },
    isFullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msg: '弹出框',
      dialogVisible: false
    }
  },
  watch: {
    isVisible: {
      handler(val) {
        this.dialogVisible = val
      },
      immediate: true
    }
  },
  methods: {
    dialogClosed() {
      this.$emit('dialogClosed')
    },
    closeDialog() {
      this.dialogClosed()
      this.$emit('update:isVisible', false)
    }
  }
}
</script>
<style>
.el-dialog.is-fullscreen {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  height: 95%;
  overflow: auto;
}
.common-dialog {

}
.dialog-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .permission-dialog-footer{
    width: 100%;
    height: 56px;
    text-align: center;
  }
  .f-item {
      width: 100%;
    }
    .d-f-container {
      width: 100%;
      min-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 0px;
      box-sizing: border-box;
    }
.el-dialog__body {
  margin-bottom: 15px;
  padding: 6px 0 10px;
}
.formBox{
  padding-right: 20px;
  padding-left: 20px;
}
.formBox .el-input{
  width: 400px;
}
.message-menu{
  display: flex;
  align-items: center;
}
.message-menu .el-button{
  margin-left: 20px;
}
.dialog-footer{
  float: right;
}
</style>
